/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
import React, { FC, useState, useEffect } from 'react';
import { PageLayout, BlogDetails, BlogFeed, PageHeading } from 'components';
import { graphql } from 'gatsby';
import Slider from 'react-slick';
import { ArticleDetailTypes } from 'types';

const ArticleDetail: FC<ArticleDetailTypes> = ({ data }: any) => {
  const {
    BlogDetail,
    BlogList,
    strapiBlogPage: { slug: blogUrl, pagetheme, title: blogTitle, banner },
    Learninghub: { slug: learningHubSlug, title: learningHubTitle },
  } = data;
  const [relatedSlides, setRelatedSlides] = useState([]);
  const [Title, setTitle] = useState(false);
  const [characterLimit, setcharacterLimit] = useState(80);
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    arrows: false,
    slidesToShow: 2,
    swipeToSlide: true,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    const limitValue = window.innerWidth < 576 ? 30 : characterLimit;
    setcharacterLimit(limitValue);

    const currentTags = BlogDetail.article_tags.map(
      (currentTag: any) => currentTag.title
    );
    const filteredBlog = BlogList.edges.filter((relatedSlide: any) => {
      let hasRelatedTag = false;
      currentTags.forEach((currentTag: any) => {
        hasRelatedTag =
          hasRelatedTag ||
          relatedSlide.node.article_tags
            .map((tag: any) => tag.title)
            .includes(currentTag);
      });
      return hasRelatedTag;
    });
    const BlogdetailList = filteredBlog.filter(
      (slide: any) => slide.node.title !== BlogDetail.title
    );
    if (filteredBlog.length > 0) {
      setTitle(true);
      setRelatedSlides(BlogdetailList);
    }
  }, []);
  return (
    <PageLayout
      className={pagetheme}
      delaySec={0.8}
      isMainBannerNav={false}
      title={BlogDetail?.seo?.title ?? BlogDetail?.title}
      description={BlogDetail?.seo?.metaDescription ?? BlogDetail?.description}
      image={
        BlogDetail?.seo?.shareImage?.localFile?.publicURL ??
        BlogDetail?.image?.localFile?.publicURL
      }
      type="Article"
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
      dealTitle="Blog page"
    >
      <div className="container">
        {BlogDetail && (
          <BlogDetails
            BlogDetail={BlogDetail}
            blogUrl={`${learningHubSlug}/${blogUrl}`}
            breadCrumb={[
              { title: 'Home', slug: '/' },
              { title: learningHubTitle, slug: `/${learningHubSlug}` },
              { title: blogTitle, slug: `/${learningHubSlug}/${blogUrl}` },
            ]}
          >
            {BlogDetail?.callToAction && (
              <a className="cta-button" href={BlogDetail?.callToAction?.link}>
                <span>{BlogDetail?.callToAction?.title}</span>
              </a>
            )}
          </BlogDetails>
        )}

        {Title && <PageHeading title="More Articles for you" />}
      </div>
      {Title && (
        <div className="blog-detail-slider">
          <Slider {...settings}>
            {relatedSlides &&
              relatedSlides.map((slide: any) => {
                return (
                  <BlogFeed
                    Blogs={slide.node}
                    blogUrl={`${learningHubSlug}/${blogUrl}`}
                    characterLimit={characterLimit}
                    index={0}
                    key={slide.node.id}
                  />
                );
              })}
          </Slider>
        </div>
      )}
    </PageLayout>
  );
};

export default ArticleDetail;
export const query = graphql`
  query BlogDetailsQuery($slug: String) {
    BlogDetail: strapiArticle(slug: { eq: $slug }) {
      title
      content
      description
      slug
      authorName
      date
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      video {
        localFile {
          publicURL
        }
      }
      imageLink
      callToAction {
        title
        link
      }
      article_tags {
        title
      }
    }
    BlogList: allStrapiArticle {
      edges {
        node {
          id
          title
          slug
          description
          content
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 283, height: 367)
              }
            }
          }
          article_tags {
            title
          }
        }
      }
    }
    strapiBlogPage {
      slug
      title
      pageTheme
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    Learninghub: strapiLearningHubPage {
      slug
      title
    }
  }
`;
